import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { fade, makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import PhoneIcon from "@material-ui/icons/Phone";
import PeopleIcon from "@material-ui/icons/People";
import AmpStoriesIcon from "@material-ui/icons/AmpStories";
import Icon from "@material-ui/core/Icon";
import { Cart } from "../cart";
import {
  useMediaQuery,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { db, auth } from "../../../firebase/config";
import HomeIcon from "@material-ui/icons/Home";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CloseIcon from "@material-ui/icons/Close";
import PersonIcon from "@material-ui/icons/Person";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import { setCurrentRestaurant } from "../../../redux/publicReducer/actions";
import NoteIcon from "@material-ui/icons/Note";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TuneIcon from "@material-ui/icons/Tune";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // zIndex: 101,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    width: "100%",
    // display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    top: "10px",
    left: "10px",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    // minWidth:"300px",
    border: "1px solid #bebebe",
    borderRadius: "7px",
    padding: "6px",
    height: "30px",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export const LowerNav = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { search, user } = useSelector((state) => state.public);
  const match = useRouteMatch();
  const history = useHistory();
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <div
      style={{
        backgroundColor: "white",
        width: "100%",
        position: "relative",
        bottom: "1px",
      }}
    >
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon style={{"cursor":"pointer"}} />
          </div>
          <form
            onSubmit={() => {
              history.push(`/${match.params.restaurantId}`);
            }}
          >
            <InputBase
              placeholder="Search…"
              value={search}
              onChange={(e) =>
                dispatch({ type: "SET_SEARCH", payload: e.target.value })
              }
              onKeyPress={(event) => {
                //console.log(event);
                if (event.key == "Enter") {
                  event.preventDefault();
                  return false;
                }
              }}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </form>
        </div>
      </Toolbar>
    </div>
  );
};

export function NavBar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { search, user } = useSelector((state) => state.public);
  const match = useRouteMatch();
  const history = useHistory();
  const isMobile = useMediaQuery("(max-width:600px)");
  const restaurantsRef = db.collection("admins").doc(match.params.restaurantId);
  const [dynamicPages, setDynamicPages] = useState([]);

  const [restaurantData, setRestaurantData] = useState({});

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const unsub = restaurantsRef.onSnapshot((doc) => {
      setRestaurantData({ ...doc.data(), id: doc.id });
    });

    return unsub;
  }, []);

  // useEffect(() => {
  //   const unsub = db
  //     .collection("contentsManagement")
  //     .where("user", "==", match.params.restaurantId)
  //     .onSnapshot((snapshot) => {
  //       let _dynamicPages = [];
  //       snapshot.docs.forEach((doc) => {
  //         _dynamicPages.push({ ...doc.data(), id: doc.id });
  //       });
  //       setDynamicPages(_dynamicPages);
  //     });

  //   return unsub;
  // }, []);

  useEffect(() => {
    if (restaurantData) {
      dispatch(setCurrentRestaurant(restaurantData));
    } else {
      dispatch(setCurrentRestaurant(""));
    }
  }, [restaurantData]);

  return (
    <>

      <Toolbar        
        elevation={0}
        style={{
          backgroundColor: "transparent",
          position: "fixed",
          zIndex: 1100,
          right: "0",
          top: "0",
          color: "black",
        }}
      >
          {/* <IconButton 
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton> */}

          {/* <Typography
            className={classes.title}
            variant={isMobile ? "caption" : "h6"}
            noWrap
            style={{ cursor: "pointer", textAlign: "center", width: "100%" }}
            onClick={() => {
              history.push(`/menu/${restaurantData.id}`);
            }}
          >
           <>
          </Typography> */}

          {/*  <img
            src="/static/AJ_Sliders_Logo.svg"
            style={{ height: "2rem", width: "auto" }}
            className={classes.title}
            onClick={() => {
              history.push("/");
            }}
          /> */}
             
          {isMobile && <div className={classes.title} />}

          <div
            style={{
              width: isMobile && "100%",
              display: "block",
              justifyContent: "flex-end",
            }}
          >
            <Cart />
          </div>
        </Toolbar>


      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div
          style={{
            height: "100vh",
            maxHeight: "100vh",
            overflowY: "auto",
            position: "fixed",
            left: "0px",
            top: "0px",
            width: isMobile ? "80vw" : "30vw",
            backgroundColor: "bisque",
            padding: "0rem 2rem",
          }}
        >
          <IconButton
            onClick={handleClose}
            style={{
              marginBottom: "1rem",
              marginLeft: "-30px",
            }}
          >
            <CloseIcon fontSize="large" style={{ fontSize: "2.5rem" }} />
          </IconButton>

          <div
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
            }}
          >
            <img
              src="/static/AJ_Sliders_LogoSqr.svg"
              style={{ width: "100%", height: "100%", borderRadius: "10%" }}
            />
          </div>

<List style={{ marginTop: "1rem" }}>
    <ListItem
              style={{
                borderBottom: "1px solid gray",
                borderTop: "1px solid gray",
                width: "90%",
                borderCollapse: "collapse",
              }}
              button
              onClick={() => {
                history.push("/");
              }}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
    </ListItem>
    <ListItem
              style={{
                borderBottom: "1px solid gray",
                borderTop: "1px solid gray",
                width: "90%",
                borderCollapse: "collapse",
              }}
              button
              onClick={() => {
                history.push("/menu");
              }}
            >
              <ListItemIcon>
                <RestaurantMenuIcon />
              </ListItemIcon>
              <ListItemText primary="Order Now" />
    </ListItem>
    <ListItem
              style={{
                borderBottom: "1px solid gray",
                width: "90%",
                borderCollapse: "collapse",
              }}
              button
              onClick={() => history.push("/about")}
            >
              <ListItemIcon>
                <AmpStoriesIcon />
              </ListItemIcon>
              <ListItemText primary="About Us" />
    </ListItem>
    <ListItem
              style={{
                borderBottom: "1px solid gray",
                width: "90%",
                borderCollapse: "collapse",
              }}
              button
              onClick={() => history.push("/team")}
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Team" />
    </ListItem>
    <ListItem
              style={{
                borderBottom: "1px solid gray",
                width: "90%",
                borderCollapse: "collapse",
              }}
              button
              onClick={() => history.push("/contact")}
            >
              <ListItemIcon>
                <PhoneIcon />
              </ListItemIcon>
              <ListItemText primary="Contact Us" />
    </ListItem>

            {dynamicPages.map((page) => {
              return (
    <ListItem
                  style={{
                    borderBottom: "1px solid gray",
                    width: "90%",
                    borderCollapse: "collapse",
                  }}
                  button
                  onClick={() => {
                    history.push(
                      `/${match.params.restaurantId}/dynamic/${page.name}/${page.id}`
                    );
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <NoteIcon />
                  </ListItemIcon>
                  <ListItemText primary={page.name} />
    </ListItem>
              );
            })}
            {user && user?.userType === "CUSTOMER" && (
              <>
    <ListItem
                  button
                  onClick={() => {}}
                  style={{ borderBottom: "1px solid gray", width: "90%" }}
                >
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary="Profile" />
    </ListItem>
    <ListItem
                  button
                  onClick={() => {
                    history.push("/order-history");
                  }}
                  style={{ borderBottom: "1px solid gray", width: "90%" }}
                >
                  <ListItemIcon>
                    <FastfoodIcon />
                  </ListItemIcon>
                  <ListItemText primary="Your Orders" />
    </ListItem>

    <ListItem
                  button
                  onClick={() => {
                    auth.signOut().then(() => {
                      history.push("/menu/" + match.params.restaurantId);
                    });
                  }}
                  style={{ borderBottom: "1px solid gray", width: "90%" }}
                >
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sign out" />
    </ListItem>
              </>
            )}
</List>
        </div>
      </Popover>
    </>
  );
}
