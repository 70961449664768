import showcaseimg from "../../../../../assets/images/about-img-left.png";
import showcaseimg1 from "../../../../../assets/images/showcase-icon.png";
import "../../../../../App.css";
// import whitespace from "../../../../../assets/css/custom.css";
import { Container, Row, Col, Button, h1, p, h6, hr } from "react-bootstrap";

export const ShowCase = () => {
  return (
    <Container className="showcase">
      <Row>
        <Col xs={6} md={6}>
          <img className="img-sm" src={showcaseimg} />
        </Col>
        <Col xs={6} md={5}>
          <div className="left margin-top-20">
            <h1>About Us</h1>
            <h4>Get to know us</h4>
          </div>

          <div className="left margin-top-20">
            <p>
              Sow Belly's is a locally and family owned business that was
              established on July 1st, 2011. We have been going strong since
              then as we're dedicated to our community. We serve traditional
              Chicago Style dishes made using the freshest ingredients at our
              restaurant. Visit us today!
            </p>
          </div>
          <div className="showcase-right-text left">
            <Col xs={6} md={3} className="img-icon">
              <img src={showcaseimg1} size="25" />
            </Col>
            <p className="white">
              <h4> Our Menu </h4>
              Start with super-fresh ingredients and cooking traditions
              <br></br>
              <Button
                className="btn-red margin-top-10 margin-bottom-10"
                href="/menu"
              >
                Order Now{" "}
              </Button>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
