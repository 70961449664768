import ReactPlayer from "react-player";
import "../../../App.css";
import { Container, Row, Col, h6, Button, p, h2, div } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ContactSlider = () => {
  return (
    <div className="ContactSlider">
      <div className="ContactHeader">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={12} sm={8} md={7}>
              <h6 className="white whitespace">
                {" "}
                Service Options: Pickup | Delivery | Catering
              </h6>
              <h1 className="left gold">No party is too big or small.</h1>

              <h6 className="white blankspace">
                {" "}
                Look no further, let Sow Bellys be your catering provider. With
                an experienced team, we have catered weddings, birthday parties,
                office meetings, graduations, sport games, celebrations, you
                name it!
              </h6>
              <h6 className="white blankspace">
                {" "}
                All our items are house made and guaranteed to leave a lasting
                impression.
              </h6>
            </Col>

            <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
