import ReactPlayer from "react-player";
import "../../../App.css";
import { Container, Card, Row, Col, h6, p, h2, div, Alert, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const OrderSlider = () => {
  return (
<div className="OrderSlider">
      <div className="OrderHeader">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col sm={12} lg={8}>
              <h1 className="left gold whitespace">
                Fresh & Tasty Food
              </h1>
                <h6 className="white">
                {" "}
                Chicago Style Food Stand. Follow us on Facebook for specials and offers!
              </h6>
              <h1 className="left gold whitespace">
                We take great care in preparing all of our foods
              </h1>
              <h6 className="white">
                {" "}
                Around the corner of 1st Street and a few steps down Galena Avenue, you'll find this little place with the big personality tucked in among the other buildings. 
              </h6>                
            </Col>
            <Col sm={0} lg={4}>
    {/*<Card>
        <Card.Img src="/static/1.jpg" />
    </Card> */}
            </Col>
           <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
</div>
    

  );
};
