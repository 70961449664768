import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { fade, makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {
  useMediaQuery,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { db, auth } from "../../../firebase/config";
import { setMainSearch } from "../../../redux/publicReducer/actions";
import HomeIcon from "@material-ui/icons/Home";
import CloseIcon from "@material-ui/icons/Close";
import PersonIcon from "@material-ui/icons/Person";
import FastfoodIcon from "@material-ui/icons/Fastfood";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // zIndex: 101,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      marginLeft: theme.spacing(4),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    zIndex: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    // border: "1px solid black",
    paddingLeft: `calc(1em + ${theme.spacing(6)}px)`,
    transition: theme.transitions.create("width"),
    width: "0px",
    [theme.breakpoints.up("xs")]: {
      width: "0ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export function MainNavigation() {
  const classes = useStyles();
  const { mainSearch, user } = useSelector((state) => state.public);
  const match = useRouteMatch();
  const history = useHistory();
  const isMobile = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  let inputRef = React.useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <AppBar
        position="sticky"
        color="inherit"
        style={{ marginBottom: "1rem" }}
        elevation={0}
      >
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            className={classes.title}
            variant="h6"
            noWrap
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push(`/home`);
            }}
          >
            Sowbellys
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <IconButton
                onClick={() => {
                  //console.log(inputRef.current);
                  inputRef.current?.focus();
                }}
              >
                <SearchIcon fontSize="large" />
              </IconButton>
            </div>
            <form
              onSubmit={() => {
                history.push("/");
              }}
            >
              <InputBase
                placeholder="Search…"
                value={mainSearch}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputRef={inputRef}
                onChange={(e) => dispatch(setMainSearch(e.target.value))}
                inputProps={{ "aria-label": "search" }}
              />
            </form>
          </div>
          {user?.userType === "CUSTOMER" && (
            <div>
              <IconButton
                onClick={() => {
                  auth.signOut().then(() => {
                    history.push("/");
                  });
                }}
              >
                <ExitToAppIcon />
              </IconButton>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div
          style={{
            height: "100vh",
            maxHeight: "100vh",
            position: "fixed",
            left: "0px",
            top: "0px",
            width: isMobile ? "80vw" : "30vw",
            padding: "0rem 2rem",
            backgroundColor: "white",
          }}
        >
          <IconButton
            onClick={handleClose}
            style={{
              marginBottom: "1rem",
              marginLeft: "-30px",
            }}
          >
            <CloseIcon fontSize="large" style={{ fontSize: "2.5rem" }} />
          </IconButton>

          <List style={{ marginTop: "1rem" }}>
            <ListItem
              style={{
                borderBottom: "1px solid gray",
                borderTop: "1px solid gray",
                width: "90%",
                borderCollapse: "collapse",
              }}
              button
              onClick={() => {
                history.push("/home");
              }}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
            {user && user?.userType === "CUSTOMER" && (
              <>
                <ListItem
                  button
                  onClick={() => {}}
                  style={{ borderBottom: "1px solid gray", width: "90%" }}
                >
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary="Profile" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    history.push("/order-history");
                  }}
                  style={{ borderBottom: "1px solid gray", width: "90%" }}
                >
                  <ListItemIcon>
                    <FastfoodIcon />
                  </ListItemIcon>
                  <ListItemText primary="Your Orders" />
                </ListItem>

                <ListItem
                  button
                  onClick={() => {
                    auth.signOut().then(() => {
                      history.push("/");
                    });
                  }}
                  style={{ borderBottom: "1px solid gray", width: "90%" }}
                >
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sign out" />
                </ListItem>
              </>
            )}
          </List>
        </div>
      </Popover>
    </>
  );
}
